<!--单品頁-->
<template>
    <div class="prod-content">
        <el-row style="margin: 5px 5px;">
            <el-col :lg="3" class="hidden-sm-and-down">&nbsp;</el-col>
            <el-col :lg="18" :xs="24" :sm="24">
                <el-row>
                    <el-breadcrumb separator="/" style="line-height: 48px;height: 48px;margin-left: 10px;">
                        <el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/category/0' }">商品分類</el-breadcrumb-item>
                        <el-breadcrumb-item v-show="productInfo.cateValues"
                                            :key="index"
                                            v-for="(item, index) in (productInfo.cateValues||'').split(',')"
                                            :to="{ path: '/category/'+item.split('!')[0] }"
                        >{{ item.split('!')[1]}}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>{{productInfo.storeName}}</el-breadcrumb-item>

                    </el-breadcrumb>
                </el-row>
                <el-row>
                    <el-col :lg="12" :xs="24" :sm="24">
                        <el-row>
                            <el-col>
                                <img :src="productInfo.image" style="max-width: 100%;cursor: pointer;border-radius: 5px;"/>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :lg="12" :xs="24" :sm="24" style="padding-left: 20px;">
                        <h3 style="margin-top: 0px;">{{productInfo.storeName}}</h3>
                        <h5 style="font-weight: normal;">{{productInfo.storeInfo}}</h5>
                        <el-row><p></p></el-row>
                        <div class="price">
                            <span class="list-price">{{productInfo.otPrice | filter_addPricePrefix}}</span>
                            <span class="sale-price">{{productInfo.price | filter_addPricePrefix}}</span>
                        </div>
                        <el-row>
                            <p>
                                <el-input-number size="small" v-model="cartNum" :min="1" :max="10">
                                </el-input-number>
                            </p>
                        </el-row>
                        <el-row>
                            <el-button @click="btnAdd2Check(productInfo,true)" title="直接購買" type="danger" size="small">直接購買</el-button>
                            <el-button @click="btnAdd2Cart(productInfo,false)" title="加入購物車"  type="danger" size="small">加入購物車</el-button>
                        </el-row>
                        <el-divider></el-divider>
                        <el-row>
                            <p>貨號: {{(productValue.DEFAULT||'').barCode}}</p>
                            <p>分類:  <span v-for="(item, index) in (productInfo.cateValues||'').split(',')" :key="index">{{ item.split('!')[1] }}</span></p>
                        </el-row>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <h2>【商品資訊】</h2>
                    <el-divider></el-divider>
                    <p v-html="productInfo.content"></p>
                    <h2>【注意事項】</h2>
                    <el-divider></el-divider>
                    <p>動物性油脂於常溫下油脂會呈現澄清狀、半固狀、油脂</p>
                    <p>皆為天然傳統製作工序的正常現象，風味不變，品質保證，敬請安心食用。</p>
                </el-row>
            </el-col>
            <el-col :lg="3" class="hidden-sm-and-down">&nbsp;</el-col>
        </el-row>
    </div>
</template>

<script>
    import {GetGoodsDetail} from '@/request/api';
    import {mapActions} from 'vuex';

    export default {
        name: '',
        components: {},
        data() {
            return {
                cartNum: 1,
                prodDtl: {},
                productInfo: {},
                productAttr: {},
                productValue: {},
                cateName: '',
            }
        },
        methods: {
            ...mapActions(['add2Cart']),
            go2Detail(prod) {
                this.$router.push('/product/' + prod.id);
            },
            btnAdd2Check(prod) {
                this.btnAdd2Cart(prod, true);
            },
            btnAdd2Cart(prod, toCheckOutFlg) {
                let _this = this;
                var productId = prod.id;
                var productAttrUnique = this.productValue.DEFAULT.id;
                var cartNum = this.cartNum;
                this.add2Cart({productId, productAttrUnique, cartNum}).then(res => {
                    if (res.code == 200) {
                        if (toCheckOutFlg) {
                            _this.$router.push('/cart');
                        }
                    } else if (res.code == 401) {
                        _this.$router.push('/login');
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            getGoodsDetail(id) {
                GetGoodsDetail(id).then(res => {
                    if (res.code == 200) {
                        this.prodDtl = res.data;
                        this.productInfo = res.data.productInfo;
                        this.productAttr = res.data.productAttr;
                        this.productValue = res.data.productValue;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            }
        }, mounted() {
            if (this.$route.params.id) {
                this.getGoodsDetail(this.$route.params.id);
            }
        }
    }
</script>
<style lang="less" scoped>
    .prod-content {
    }

    .price {
        display: flex;
        justify-content: start;

        .list-price {
            color: #BDBDBD;
            text-decoration: line-through;
            margin-right: 10px;
        }

        .sale-price {
            font-weight: bold;
            color: #930e11;
        }
    }
</style>
